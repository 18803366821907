import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor() { }


  getErrors(err) {
    let tmp: string[] = [];
    if(err){
      let errors : string[] = Object.values(err['errors']);
      for (let errorList of errors) {
        for (let error of errorList) {
          tmp.push(error);
        }
      }
    }
    return tmp;
  }

}
