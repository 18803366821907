import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from '../../../shared/models/page.model';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../shared/services/auth.service';
import { StockModel } from '../../../shared/models/stock.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { StockService } from '../../../shared/services/stock.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { NotificationModel } from '../../../shared/models/notification.model';

@Component({
	selector: 'app-notification-list',
	templateUrl: './notification-list.component.html',
	styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {


	@ViewChild('myTable',{static:true}) table: DatatableComponent;

	freeSearch: string;
	ColumnMode = ColumnMode;
	notifications: NotificationModel[];
	page: Page = new Page();
	currentPage = 1;
	errors: String[] = [];
	searchParams = { per_page: environment.per_page, page: this.currentPage };
	columns = [];
	@ViewChild('restockModal', { static: true }) restockModal: ModalDirective;
	restockForm: FormGroup;

	rows = [];


	constructor(
		private router: Router,
		private stockService: StockService,
		private notificationService : NotificationService,
		private authService: AuthService,
		private fb: FormBuilder,
		private messageService: MessageService,
		private errorHandler: ErrorHandlingService,
	) { }

	// reference:string;
	//   description:string;
	//   stock_amount:number;
	//   purchase_price:number;
	//   supplier:SupplierModel;

	ngOnInit() {
		this.loadNotifications();
		this.initRestockForm();
		this.columns = [
			{ name: 'Message', flexGrow: 1, sortable: true, cellClass: 'notification_message', headerClass: 'notification_message' }
		];
	}

	initRestockForm(input?) {
		input = input || {};
		this.restockForm = this.fb.group({
			reference: [input.reference, Validators.required],
			amount: [input.amount, Validators.required],
			purchase_price: [input.purchase_price, [Validators.required]],
			notes: [input.notes],
		});
	}

	isSystemAdmin(): boolean {
		if (this.authService.getUserRole() == 1) {
			return true;
		}
		return false;
	}

	onSort(event) {

	}

	isTouchedStock(controlName) {
		if (this.restockForm.get(controlName).touched && this.restockForm.get(controlName).invalid) {
			return true;
		}
		else {
			return false;
		}
	}

	toggleNotification(){
		let _this = this;
		let val = (this.activeNotification['isSuggestion'] ? this.activeNotification.data.suggested_order_amount : this.activeNotification.data.reorder_amount);
		this.restockForm.get("amount").setValue(val);
	  }

	timeout: any;
	loadNotifications() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		let _this = this;

		this.timeout = setTimeout(() => {
			this.notificationService.getAll(this.searchParams).subscribe(res => {
				_this.page = res['data'];
				_this.notifications = res['data']['data'];
				_this.rows = [];
				//console.log(res['data']['data'])
				for (let notification of _this.notifications) {
					_this.rows.push(
						{
							id: notification.id,
							message: notification.title,
							data: notification,
							is_complete:notification.is_complete,
							type: notification.type
						}
					);
				}
			});
		}, 350);

	}

	setPage(pageInfo) {
		this.currentPage = pageInfo.offset + 1;
		//console.log(pageInfo);
		this.searchParams.page = this.currentPage;
		//console.log(this.currentPage);
		this.loadNotifications();
	}

	ngAfterViewInit() {
		this.table.bodyComponent.updatePage = function (direction: string): void {
			let offset = this.indexes.first / this.pageSize;

			if (direction === 'up') {
				offset = Math.ceil(offset);
			} else if (direction === 'down') {
				offset = Math.floor(offset);
			}

			if (direction !== undefined && !isNaN(offset)) {
				this.page.emit({ offset });
			}
		}
	}

	onFocus(event) {
		debugger;
		if (event['type'] == 'click') {
			let id = event['row']['id'];
			this.router.navigate(['/stock/' + id]);
		}
	}

	clearFilters() {
		this.freeSearch = "";
		this.updateFilters();
	}

	updateFilters() {
		this.searchParams = { per_page: environment.per_page, page: 1 };
		if (this.freeSearch) {
			this.searchParams['q'] = this.freeSearch;
		}
		this.loadNotifications();
	}

	openRestockModal(activeNotification) {
		this.restockModal.show();
		this.activeNotification = activeNotification;
		console.log(activeNotification);
		this.restockForm.get("reference").setValue(activeNotification.stock.reference);
		let val = (activeNotification.isSuggestion ? activeNotification.data.suggested_order_amount : activeNotification.data.reorder_amount);
		this.restockForm.get("amount").setValue(val);
		this.restockForm.get("purchase_price").setValue(activeNotification.stock.purchase_price);
	}
	activeNotification:NotificationModel;
	saveRestock() {
		let _this = this;
    let rawValue = this.restockForm.getRawValue();
    rawValue['notification_id'] = _this.activeNotification.id;
		this.stockService.restock(rawValue).subscribe(res => {
			// this.router.navigate(['/stock/'+res['data']['id']]);
      _this.restockForm.reset();
      _this.loadNotifications();
			_this.restockModal.hide();
			this.messageService.success(`Stock ${rawValue['reference']} has been restocked. New stock level is ${res['data']['stock_amount']}`, { Position: 'top', Style: 'bar', Duration: 3000 });
		},
			err => {
				_this.errors = _this.errorHandler.getErrors(err);
				console.log(err); ``
			});
  }

}
