import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../@pages/components/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { pgSelectModule} from '../../@pages/components/select/select.module';
import { MessageModule } from '../../@pages/components/message/message.module';
import { MessageService } from '../../@pages/components/message/message.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { pgSwitchModule } from '../../@pages/components/switch/switch.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TextMaskModule } from 'angular2-text-mask';
import { NotificationsComponent } from './quickview/notifications.component';
import { NotificationListComponent } from './list/notification-list.component';
import { NotificationRoutes } from './notification.routing';

@NgModule({
  declarations:[
    NotificationsComponent,
    NotificationListComponent
  ],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule, 
    SharedModule,
    NgxDatatableModule,
    pgSelectModule,
    MessageModule,
    ModalModule,
    NgxIntlTelInputModule,
    TextMaskModule,
    pgSwitchModule,
    RouterModule.forChild(NotificationRoutes),
  ],
  exports: [NotificationsComponent],
  providers:[MessageService]
})
export class NotificationModule { }
