import { Routes } from '@angular/router';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';
import { NotificationListComponent } from './list/notification-list.component';

export const NotificationRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGaurd,RoleGuard],
    component: NotificationListComponent
  }
];
