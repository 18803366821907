import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '../../../shared/services/notification.service';
import { Router } from '@angular/router';
import { StockService } from '../../../shared/services/stock.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  visible:boolean = true;
  notifications : any = [];

  constructor(
    private notificationService : NotificationService,
    private router : Router,
    private stockService : StockService,
    private messageService: MessageService,
    private errorHandler : ErrorHandlingService,
    private fb : FormBuilder
  ) {}

  ngOnInit() {
    this.loadNotifications();
    this.initRestockForm();
  }
  activeNotification:any;
  restock(notification){
    if(notification.is_complete || notification.type != 'reorder'){
      return;
    }
    this.activeNotification = notification;
    this.restockForm.get("reference").setValue(notification.stock.reference);
    let val = (notification.isSuggestion ? notification.data.suggested_order_amount : notification.data.reorder_amount);
    this.restockForm.get("amount").setValue(val);
    this.restockForm.get("purchase_price").setValue(notification.stock.purchase_price);
    this.restockModal.show();
  }

  loadNotifications(){
    let _this = this;
    this.notificationService.getAll({}).subscribe(res => {
      _this.notifications = res['data']['data'];
    },
    err => {
      console.log(err);
    });
  }

  dismiss(id){
    this.notificationService.delete(id).subscribe(res => {
      this.loadNotifications();
    },
    err => {
      console.log(err);
    });
    for(let n = 0;n < this.notifications.length;n++){
      if(this.notifications[n].id == id){
        this.notifications.splice(n,1);
        break;
      }
    }
  }
  @ViewChild('restockModal', { static: true }) restockModal: ModalDirective;
  restockForm:FormGroup;
  errors:any[] = [];
  saveRestock() {
		let _this = this;
    let rawValue = this.restockForm.getRawValue();
    rawValue['notification_id'] = _this.activeNotification.id;
		this.stockService.restock(rawValue).subscribe(res => {
			// this.router.navigate(['/stock/'+res['data']['id']]);
      _this.restockForm.reset();
      _this.loadNotifications();
			_this.restockModal.hide();
			this.messageService.success(`Stock ${rawValue['reference']} has been restocked. New stock level is ${res['data']['stock_amount']}`, { Position: 'top', Style: 'bar', Duration: 3000 });
		},
			err => {
				_this.errors = _this.errorHandler.getErrors(err);
				console.log(err); ``
			});
  }

  initRestockForm(input?) {
		input = input || {};
		this.restockForm = this.fb.group({
			reference: [input.reference, Validators.required],
			amount: [input.amount, Validators.required],
			purchase_price: [input.purchase_price, [Validators.required]],
			notes: [input.notes],
		});
	}
  
  isTouchedStock(controlName){
    if (this.restockForm.get(controlName).touched && this.restockForm.get(controlName).invalid) {
      return true;
    }
    else {
      return false;
    }
  }

  toggleNotification(){
    let _this = this;
    let val = (this.activeNotification.isSuggestion ? this.activeNotification.data.suggested_order_amount : this.activeNotification.data.reorder_amount);
    this.restockForm.get("amount").setValue(val);
  }

  toggle(){
    this.visible = !this.visible;
  }

  navigateTest(){
    this.router.navigate(['tracking']);
  }

}
