import { Injectable } from '@angular/core';
import { Roles, RoleAccess } from '../models/roles.model';

@Injectable()
export class RoleService {
  public roles: Roles = {
    1: {
      role: 'Administrator',
      defaultRoute: '/dashboard',
      routeAccess: {
        dashboard: true,
        stock: true,
        user:true,
        support:true,
        profile: true,
        supplier: true,
        stocktype: true,
        door: true,
        order: true,
        extra: true,
        client: true,
        notification: true,
        stockcolor:true,
        ['purchase-order']:true,
        ['sales-order']:true
      }
    },
    2: {
      role: 'Accounts',
      defaultRoute: '/dashboard',
      routeAccess: {
        dashboard: true,
        stock: true,
        user:true,
        support:true,
        profile: true,
        supplier: true,
        door: true,
        order: true,
        extra: true,
        client: true,
        notification: true,
        stockcolor:true,
        ['purchase-order']:true,
        ['sales-order']:true
      }
    },
    3: {
      role: 'Sales',
      defaultRoute: '/dashboard',
      routeAccess: {
        dashboard: true,
        stock: true,
        user:false,
        support:true,
        profile: true,
        supplier: true,
        door: true,
        order: true,
        extra: true,
        client: true,
        notification: true,
        stockcolor:true,
        ['purchase-order']:true,
        ['sales-order']:true
      }
    },
    4: {
      role: 'Measurer',
      defaultRoute: '/dashboard',
      routeAccess: {
        dashboard: true,
        stock: false,
        user:false,
        support:true,
        profile: true,
        supplier: false,
        door: true,
        order: true,
        extra: true,
        client: true,
        stockcolor:true,
        notification: true,
        ['purchase-order']:true,
        ['sales-order']:true
      }
    },
    5: {
      role: 'Installer',
      defaultRoute: '/dashboard',
      routeAccess: {
        dashboard: true,
        stock: false,
        user:false,
        support:true,
        profile: true,
        supplier: false,
        door: true,
        order: true,
        extra: true,
        client: true,
        stockcolor:true,
        notification: true,
        ['purchase-order']:true,
        ['sales-order']:true
      }
    },
    6: {
      role: 'Gypsum',
      defaultRoute: '/dashboard',
      routeAccess: {
        dashboard: true,
        stock: false,
        user:false,
        support:true,
        profile: true,
        supplier: false,
        door: true,
        order: true,
        extra: true,
        client: true,
        stockcolor:true,
        notification: true,
        ['purchase-order']:true,
        ['sales-order']:true
      }
    },
    7: {
      role: 'Tiling',
      defaultRoute: '/dashboard',
      routeAccess: {
        dashboard: true,
        stock: false,
        user:false,
        support:true,
        profile: true,
        supplier: false,
        door: true,
        order: true,
        extra: true,
        client: true,
        stockcolor:true,
        notification: true,
        ['purchase-order']:true,
        ['sales-order']:true
      }
    },
    8: {
      role: 'Supplier',
      defaultRoute: '/dashboard',
      routeAccess: {
        dashboard: true,
        stock: false,
        user:false,
        support:true,
        profile: true,
        supplier: false,
        door: true,
        order: true,
        extra: true,
        client: true,
        notification: true,
        stockcolor:true,
        ['purchase-order']:true,
        ['sales-order']:true
      }
    },
    9: {
      role: 'Client',
      defaultRoute: '/dashboard',
      routeAccess: {
        dashboard: true,
        stock: false,
        user:false,
        support:true,
        profile: true,
        supplier: false,
        door: true,
        order: true,
        extra: true,
        client: true,
        notification: true,
        ['purchase-order']:true,
        ['sales-order']:true
      }
    }
    

  };

  constructor() { }

  getAccess(role: number): RoleAccess {
    return <RoleAccess>this.roles[role];
  }
}
