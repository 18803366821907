import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/stock/notification`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/stock/notification/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/stock/notification/`+id,formData);
    }

    getCount(){
      return this.http.get(`${environment.baseUrl}/stock/notification/pending`);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/stock/notification/`+id);
    }


}
