import { Routes } from '@angular/router';
//Layouts
import { 
  SimplyWhiteLayout,
  BlankSimplywhiteComponent
} from './@pages/layouts';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';


export const AppRoutes: Routes = [

  {
    path: '',
    data: {
        breadcrumb: 'Home'
    },
    component: BlankSimplywhiteComponent,
    loadChildren: "./view/session/session.module#SessionModule"
  },
  {
    path: 'dashboard',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/dashboard/dashboard.module#DashboardModule"
  },
  {
    path: 'user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/user/user.module#UserModule"
  },
  {
    path: 'stocktype',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/stocktype/stocktype.module#StockTypeModule"
  },
  {
    path: 'stockcolor',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/stockcolor/stockcolor.module#StockColorModule"
  },
  {
    path: 'door',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/door/door.module#DoorModule"
  },
  {
    path: 'order',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/order/order.module#OrderModule"
  },
  {
    path: 'extra',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/extra/extra.module#ExtraModule"
  },
  {
    path: 'client',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/client/client.module#ClientModule"
  },
  {
    path: 'stock',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/stock/stock.module#StockModule"
  },
  {
    path: 'support',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/support/support.module#SupportModule"
  },
  {
    path: 'supplier',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/supplier/supplier.module#SupplierModule"
  },
  
  {
    path: 'notification',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/notification/notification.module#NotificationModule"
  },

  {
    path: 'purchase-order',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/purchase-order/purchase-order.module#PurchaseOrderModule"
  },


  {
    path: 'session',
    data: {
        breadcrumb: 'Home'
    },
    component: BlankSimplywhiteComponent,
    loadChildren: "./view/session/session.module#SessionModule"
  },
  {
    path: 'profile',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
        breadcrumb: 'Home'
    },
    component: SimplyWhiteLayout,
    loadChildren: "./view/profile/profile.module#ProfileModule"
  },
];
